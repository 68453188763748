:root {
	--solid-black: #000;
	--solid-white: #fff;
	--solid-transparent: transparent;

	--neutral-100: #E5E5E5;
	--neutral-200: #CCCCCC;
	--neutral-300: #B2B2B2;
	--neutral-400: #999999;
	--neutral-500: #808080;
	--neutral-600: #666666;
	--neutral-700: #4D4D4D;
	--neutral-800: #333333;
	--neutral-900: #1A1A1A;

	--dc-primary-offwhite: #F1F0EC;
	--dc-primary-clay: #A38876;
	--dc-primary-charcoal: #333333;

	--dc-highlight-choral: #F3C290;

	--dc-contrast-violet: #BFBADF;
	--dc-contrast-blue: #446AC9;
	--dc-contrast-tuerkis: #B4D2DC;
	--dc-contrast-blaugruen: #D2E7D7;
	--dc-contrast-limon: #EAE134;
	--dc-contrast-olive: #606946;
	--dc-contrast-hellgruen: #DFE8B9;
	--dc-contrast-taupe: #E6D5C0;
	--dc-contrast-goldbraun: #827250;
	--dc-contrast-braun: #896E5B;
	--dc-contrast-rostrot: #AA6134;
	--dc-contrast-dunkelrot: #6B2423;
	--dc-contrast-steingrau: #5D5F52;
	--dc-contrast-dunkelgrau: #333333;

	--dc-elyo-white: #F6F6F4;
	--dc-elyo-black: #363835;
	--dc-elyo-iceblue: #A1E0E8;
	--dc-elyo-blue: #066DC4;
	--dc-elyo-dunkelrot: #432A2F;
	--dc-elyo-feuerrot: #EF4832;
	--dc-elyo-orange: #FF7C00;
	--dc-elyo-rotorange: #E65900;

	--dc-aphelie-rosa: #F8DFE1;
	--dc-aphelie-gold: #AA7318;
	--dc-aphelie-pistacio: #EDF1DD;
	--dc-aphelie-dunkelblau: #202849;

	--dc-indicator-success: #549B78;
	--dc-indicator-error: #DD422B;
	--dc-indicator-warning: #F78A39;

}